<template>
  <div class="contaner_w">
    <new-header :config="headerConfig"></new-header>
    <div style="width: 80%; margin-left: 10%">
      <van-steps :active="active" active-color="#007DFF" @click-step="tabSelect" v-if="!overdue">
        <van-step>信息确认</van-step>
        <van-step v-if="type != 2">销售合同</van-step>
        <van-step v-if="type != 3">工分合约</van-step>
      </van-steps>
    </div>
    <div v-if="active == 0" class="enterprise_content">
      <div class="top_text">
        初次申请时请确认用户信息，后续若个人信息不正确请更新工会合约信息后操作：<span style="color: #078bff" @click="mineBtn">个人中心</span>
      </div>
      <div v-if="id" class="top_text" style="color:red">
          *在修改合同信息时，需要保持和个人资料中工分合同信息一致
      </div>
      <div class="info_text">
        <div style="display: flex">
          <div class="bar"></div>
          <div>信息确认</div>
        </div>
        <div v-if="type != 3">{{ id ? "工分：" : "可兑工分：" }}{{ tableDate.exchange_share }}</div>
      </div>
      <div class="from_content">
        <div class="from_box1">
          <div>姓名:</div>
          <input v-model="union_shareholder_info.name" :disabled="disabled" />
        </div>
        <div class="from_box1">
          <div>身份证号:</div>
          <input v-model="union_shareholder_info.Idcard" :disabled="disabled" />
        </div>
        <div class="from_box1">
          <div>详细地址:</div>
          <textarea v-model="union_shareholder_info.address" :disabled="disabled"></textarea>
        </div>
        <div class="from_box1">
          <div>联系方式:</div>
          <input v-model="union_shareholder_info.phone" :disabled="disabled" />
        </div>

        <div class="from_box1">
          <div>签名上传:</div>
          <div class="singin_box" @click="singinBtn">
            <!-- <img
              @click.stop="namePreview"
              v-if="union_shareholder_info.nameImg"
              :src="union_shareholder_info.nameImg"
            /> -->

            <img v-if="union_shareholder_info.nameImg" :src="union_shareholder_info.nameImg" />
          </div>
        </div>
      </div>
      <div class="neth_btn_e" @click="nethBtnE">下一步</div>
    </div>

    <!-- 销售合同 -->
    <div v-if="active == 1">
      <application-formNew :shareDate="shareDate" :dataTime="dataTime" :dataTime1="dataTime1" :imgToken="imgToken"
        :nameImg="union_shareholder_info.nameImg" @cloneBtn="cloneBtn" @confim="confim" @nethBtn="nethBtn"
        @submit="submit"></application-formNew>
    </div>

    <!-- 工分合同 -->
    <div v-if="active == 2">
      <application-form :shareDate="shareDate" :dataTime="dataTime" :dataTime1="dataTime1" :imgToken="imgToken"
        :nameImg="union_shareholder_info.nameImg" @cloneBtn="cloneBtn" @confim="confim"
        @submit="submit"></application-form>
    </div>

    <!-- 签字弹窗 -->
    <div class="signtureShow" v-if="signtureShow" @click="signtureShow">
      <div class="signtureShow_box">
        <catSignature :imgWidth="imgWidth" @signatureBtn="signatureBtn" @cloneBtn="cloneBtn1" :imgToken="imgToken"
          @cloneImg="cloneImg" @imgUblod="imgUblod"></catSignature>
      </div>
    </div>


    <!-- 提示框 -->
    <van-dialog v-model="showTotal" :show-confirm-button="false" width="85%">
      <div class="cancel_box">
        <img src="https://cdn.health.healthplatform.xyz/Fjmn3KOW5Zax17HdrGyhlwktZQOw" />
        <div class="text_box">
          <img @click="cloneBtn" src="../../assets/cloneBtn2.png" />
          <div class="message_title">温馨提示</div>
          <div class="message_text">
            已完成续签
          </div>
          <div class="sure_btn" @click="sureBtn">确认</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import applicationForm from "../../components/expenditure/applicationForm";
import applicationFormNew from "../../components/expenditure/applicationFormNew";
// import uploadImg from "../../components/uploadImg";
import catSignature from "../../components/catSignature.vue";
// 数据
import { ImagePreview } from "vant";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
import valid from "@/utils/valid";
export default {
  data() {
    return {
      headerConfig: {
        show: true,
        title: '企业工分',
      },
      signtureShow: false,
      imgToken: "", //上传图片token
      show: false,
      radioConfig: {
        value: "",
        list: [
          { checked: true, title: "增值兑换", ladel: 1 },
          { checked: false, title: "分享补金兑换", ladel: 2 },
        ],
      },
      isNameShow: true,
      active: 0,
      // 合同信息
      union_shareholder_info: {
        nameImg: "",
        address: "",
        name: "",
        Idcard: "",
        phone: "",
        // contractCode: "",
      },
      tableDate: {},
      disabled: false,//股东信息是否可以修改
      userFrom: {
        union_info: {
          bank: {},
        },
      },
      shareDate: {},
      options: {
        status: "",
      },
      dataTime: "", //合同生成时间
      dataTime1: "", //合同到期时间
      type: "",
      id: "",
      time: "", //订单生成时间
      overdue: "", //单独续签
      showTotal: false,
    };
  },
  components: {
    newHeader,
    applicationForm,
    applicationFormNew,
    // uploadImg
    catSignature,
  },
  created() {
    // 区分是否有销售合同，没有直接生成股份合同
    this.type = this.$route.query.type;

    document.title = "";
    // 上传图片token
    this.getToken();
    // 获取合同提交信息
    if (JSON.parse(localStorage.getItem("workData"))) {
      this.tableDate = JSON.parse(localStorage.getItem("workData"));
    }

    // 进行到步骤
    if (localStorage.getItem("active")) {
      this.active = localStorage.getItem("active");
    }

    // 合同续签，单独续签
    if (this.$route.query.overdue == 1) {
      this.overdue = this.$route.query.overdue;
      this.active = 1;
      this.bgcTitle = "合约续签";
      this.getUserInfo(1);
    }


    // 用户扫码修改合同
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.time = this.$route.query.time;
      // 二维码30分钟有效期
      let statue = Math.floor(new Date().getTime() / 1000) - this.time > 30 * 60;
      this.headerConfig.title = "合同修改"
      if (statue) {
        Toast("二维码失效,请重新生成");
        return false;
      }
      this.getContent(this.$route.query.id);
    }

    this.getUserInfo(1);
    this.getUser();
    // 获取生成合同时间
    this.getdateNoTime(new Date().getTime());
  },
  methods: {
    tabSelect(index) {
      if (this.active > index) {
        this.active = index;
      }
    },
    // 个人中心
    mineBtn() {
      // 清空页面栈
      // this.$router.replace({ path: '/' });
      this.$router.replace("../lador/ladorLogin")
      console.log(this.$router.history)
    },
    // 获取合同
    async getContent(id) {
      let data = {
        contract_id: id,
      };
      let res = await apiCommon.readContribution_api(data);
      if (res.code == 0) {
        console.log(res);
        this.tableDate.exchange_share = res.data.contract_shares;
        this.type = res.data.contract_type == 1 ? 3 : res.data.contract_type;
        this.getUserInfo();
      }else{
        Toast(res.error);
        setTimeout(e=>{
          console.log(e)
          // 清空页面栈
          // this.$router.replace({ path: '/' });
          this.$router.replace("../lador/ladorLogin")
          // this.$router.push("../lador/ladorLogin")
        },1000)
      }
    },
    async getUserInfo() {
      let res = await apiCommon.getuser_api();
      if (res.code == 0) {
        // 有股东信息
        if (res.data.union_shareholder_info) {
          this.union_shareholder_info = res.data.union_shareholder_info;
          // 修改用户信息，可以修改信息
          if (this.id) {
            this.disabled = false
          } else {
            this.disabled = true
          }
        } else {
          // 没有股东信息，借用会员信息
          this.union_shareholder_info.name = res.data.member.member_nick
          this.union_shareholder_info.phone = res.data.member.member_mobile
          this.disabled = false
        }
        if (this.overdue) {
          this.shareDate = this.union_shareholder_info;
          localStorage.setItem("shareDate", JSON.stringify(this.shareDate));
          this.getUser();
        }
      }
    },

    sureBtn() {
      this.$router.go(-1)
    },
    // 获取合同编号
    getUser() {
      if (localStorage.getItem("shareDate")) {
        this.shareDate = JSON.parse(localStorage.getItem("shareDate"));

        // 合同编号
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        this.shareDate.contractCode = `LDGH-${year}${month < 10 ? "0" + month : month}${day < 10 ? "0" + day : day
          }${this.shareDate.phone.slice(-4)}`;
      }
    },
    async nethBtn(img) {
      this.shareDate.contract_img_1 = img;

      // 单独续签
      if (this.overdue) {
        let data = {
          contract_file: img
        }
        let res = await apiCommon.shareholderRenew(data);
        if (res.code == 0) {
          this.showTotal = true
        } else {
          Toast(res.error)
        }
      } else {
        localStorage.setItem("shareDate", JSON.stringify(this.shareDate));
        if (this.type == 3) {
          this.submit();
        } else {
          this.active = 2;
          localStorage.setItem("active", 2);
        }
      }
    },
    async getToken() {
      let res = await apiCommon.getImgToken();
      if (!res || typeof res === "string" || res.error)
        return Toast(res ? res.error || res : "获取七牛云信息失败!");
      this.imgToken = res.data;
    },
    cloneBtn() { },
    confim() { },
    getdateNoTime(date) {
      var now = new Date(parseInt(date)),
        y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
      this.dataTime = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      this.dataTime1 =
        y + 3 + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
    },
    submitBtn() {
      this.show = true;
    },
    // 签名
    singinBtn() {
      // if(!this.disabled){
      this.signtureShow = true;
      // }
    },
    cloneImg() {
      this.signtureShow = false;
    },
    cloneBtn1() {
      this.signtureShow = false;
    },
    // 预览
    namePreview() {
      ImagePreview([this.union_shareholder_info.nameImg]);
    },

    // 合同
    nethBtnE() {
      console.log(this.union_shareholder_info, "this.union_shareholder_info");
      for (var i in this.union_shareholder_info) {
        if (!this.union_shareholder_info[i]) {
          Toast("请完善表单");
          return false;
        }
      }
      if (!valid.isMobile(this.union_shareholder_info.phone)) {
        Toast("请输入正确的手机号");
        this.loadingShow = false;
        return false;
      }
      this.union_shareholder_info.exchange_share = this.tableDate.exchange_share;
      localStorage.setItem("shareDate", JSON.stringify(this.union_shareholder_info));
      if (this.type == 2) {
        localStorage.setItem("active", 2);
      } else {
        localStorage.setItem("active", 1);
      }

      this.getUser();
      if (this.type == 1) {
        this.active = 1;
      } else if (this.type == 2) {
        this.active = 2;
      } else {
        this.active = 1;
      }
    },

    signatureBtn() {
      this.toastShow = true;
      this.configData.title = "签名不能为空";
      setTimeout((e) => {
        console.log(e);
        this.toastShow = false;
      }, 1000);
    },
    imgUblod(img) {
      this.signtureShow = false;
      this.union_shareholder_info.nameImg = img;
      this.toastShow = true;
      this.configData.title = "签名完成";
      setTimeout(() => {
        this.toastShow = false;
      }, 1000);
      this.popShow = true;
    },

    submit(img) {
      Toast.loading({
        message: "提交中...",
        // forbidClick: true,
      });
      let shareDate = JSON.parse(localStorage.getItem("shareDate"));
      let workData = JSON.parse(localStorage.getItem("workData"));
      // 二维码修改
      let data;
      if (this.id) {
        data = {
          contract_id: this.id,
          time: this.time,
        };
        if (this.active != 2) {
          data.contract_file = shareDate.contract_img_1;
        } else {
          data.contract_file = img;
        }
        this.detailsPut(data);

        // 新增
      } else {

        if (this.type != 3) {
          data = {
            exchange_type: workData.exchange_type,
            exchange_module: workData.exchange_module,
            exchange_share: workData.exchange_share,
            contract_file: shareDate.contract_img_1,
            union_shareholder_info: {
              address: shareDate.address,
              userAddress: shareDate.address,
              nameImg: shareDate.nameImg,
              Idcard: shareDate.Idcard,
              phone: shareDate.phone,
              name: shareDate.name,
            },
            contract_share_file: "",
          };
        } else {
          data = {
            contract_file: shareDate.contract_img_1,
            union_shareholder_info: {
              address: shareDate.address,
              userAddress: shareDate.address,
              nameImg: shareDate.nameImg,
              Idcard: shareDate.Idcard,
              phone: shareDate.phone,
              name: shareDate.name,
            },
            contract_share_file: "",
          };
        }
        if (img) {
          data.contract_share_file = img;
        } else {
          delete data.contract_share_file;
        }
        if(this.type!=3){
          if (workData.exchange_type == 2) {
            data.super_id = workData.super_id;
          }
        }
        
        this.userShare(img, data)
      }
    },

    // 工分申请单独成为股东
    async userShare(img, data) {
      if (img) {
        // alert(img)
        apiCommon.ContributionExchangeSharePost_api(data).then((res) => {
          Toast.clear()
          if (res.code == 0) {
            Toast("提交成功");
            localStorage.removeItem("contentList");
            localStorage.removeItem("active");
            localStorage.removeItem("shareDate");
            localStorage.removeItem("workData");
            localStorage.removeItem("storeList");
            localStorage.removeItem("selectList");
            // 清空页面栈
            // this.$router.go(-1);
            // this.$router.replace({ path: '/' });
            // this.$router.push("../lador/ladorLogin" );
            this.$router.replace({ path: "../lador/ladorLogin" });
          } else {
            Toast(res.error);
          }
        });
      } else {
        apiCommon.ContributionShareholderRenew_api(data).then((res) => {
          Toast.clear()
          if (res.code == 0) {
            Toast("提交成功");
            localStorage.removeItem("contentList");
            localStorage.removeItem("active");
            localStorage.removeItem("shareDate");
            localStorage.removeItem("workData");
            localStorage.removeItem("storeList");
            localStorage.removeItem("selectList");
            // 清空页面栈
            // this.$router.replace({ path: '/' });
            // this.$router.push("../lador/ladorLogin" );
            this.$router.replace({ path: "../lador/ladorLogin" });
            // this.$router.go(-1);
          } else {
            Toast(res.error);
          }
        });
      }

    },
    async detailsPut(data) {
      apiCommon.detailsPutContent(data).then((res) => {
        if (res.code == 0) {
          Toast("提交成功");
          localStorage.removeItem("contentList");
          localStorage.removeItem("active");
          localStorage.removeItem("shareDate");
          localStorage.removeItem("workData");
          localStorage.removeItem("storeList");
          localStorage.removeItem("selectList");
          this.$router.replace({ path: "../workpoints/replacement" });
        } else {
          Toast(res.error);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.contaner_w {
  /* background-color: #F9F9FB; */
  width: 100%;
}

.message_e {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.8);
}

.message_e span {
  color: #078bff;
}

.enterprise_content {
  padding: 0 50px;
  box-sizing: border-box;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.8);
}

.top_text {
  margin: 20px 0 30px;
  line-height: 40px;
}

.info_text {
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #1a1a1a;
  margin-top: 57px;
}

.info_text .bar {
  width: 6px;
  height: 24px;
  background: #078bff;
  border-radius: 0px 0px 0px 0px;
  margin: 5px 10px 0 0;
}

.from_box1 {
  display: flex;
  line-height: 66px;
  margin-top: 35px;
}

.from_box1 div {
  width: 120px;
  text-align: right;
  margin-right: 15px;
}

.from_box1 input {
  width: 500px;
  height: 66px;
  background: #f6f6f6;
  border-radius: 10px 10px 10px 10px;
  padding: 16px 21px;
  box-sizing: border-box;
  border: none;
}

textarea {
  width: 500px;
  height: 120px;
  background: #f6f6f6;
  border-radius: 10px 10px 10px 10px;
  border: none;
  padding: 20px 10px;
  box-sizing: border-box;
}

.from_box1 .singin_box {
  width: 500px;
  height: 180px;
  background: #f6f6f6;
  border-radius: 10px 10px 10px 10px;
  padding: 19px 163px;
  box-sizing: border-box;
}

.singin_box img {
  width: 160px;
  height: 142px;
  border-radius: 0px 0px 0px 0px;
  transform: rotate(-90deg);
}

.signtureShow {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.signtureShow_box {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #fed3a8 0%, #f6ebda 100%);
  margin: 0px auto 0;
  border-radius: 15px;
  padding-bottom: 20px;
}

.neth_btn_e {
  width: 600px;
  height: 80px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin: 221px auto 100px;
  text-align: center;
  line-height: 80px;
}

.confim_btn {
  position: fixed;
  bottom: 50px;
  left: 75px;
}

.cancel_box {
  width: 100%;
  height: 100%;
}

.cancel_box img {
  width: 100%;
  height: 100%;
}

.text_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.message_title {
  margin: 10px 0 50px;
}

.van-dialog {
  width: 500px
}

.message_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 42px;
  line-height: 35px;
  color: red;
  width: 528px;
  margin: 31px auto 40px;
  text-align: center;
}

.sure_btn {
  width: 180px;
  height: 68px;
  background: linear-gradient(179deg, #ff6f51 0%, #ff0639 100%);
  border-radius: 34px 34px 34px 34px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
  line-height: 68px;
  margin: 36px auto 31px;
}

.text1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: rgba(26, 26, 26, 0.6);
  margin-bottom: 24px;
}

.rule_text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #1a1a1a;
}

.rule_text span {
  color: #007dff;
}

.text_box img {
  width: 25px;
  height: 25px;
  margin-top: 52px;
  margin-left: 450px;
}

.progress_box {
  padding: 50px 30px;
  box-sizing: border-box;
  position: relative;

  .left_price {
    left: 20px;
    position: absolute;
    top: 3px;
  }

  .right_rate {
    right: 20px;
    top: 3px;
    position: absolute;
  }
}
</style>
